.jumbotron {
  background: linear-gradient(225deg, #e22128, #f4b20c);
}

.modal-wide {
  .modal-dialog {
    max-width: 1000px;
  }
}

p.preview-text {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.page-preview {
  background: #fff;
  box-shadow:
    0 1px 1px rgba(0,0,0,0.15),
    0 10px 0 -5px #eee,
    0 10px 1px -4px rgba(0,0,0,0.15),
    0 20px 0 -10px #eee,
    0 20px 1px -9px rgba(0,0,0,0.15);
  padding: 30px;

  h1 {
    font-family: 'Times New Roman';
    font-size: 12px;
    font-variant: small-caps;
  }

  p {
    font-family: 'Times New Roman';
    font-size: 12px;
    text-align: justify;
  }

  .text-muted {
    opacity: 0.5;
  }
}

.page-preview.singleton {
  p {
    margin: 0;
  }
}

.doc-editor {
  position: fixed;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 33.333333%;
  width: 66.666666%;
  z-index: 99;
  display: flex;
  flex-flow: column;

  * {
    flex: 0 1 auto;
  }

  .tree-view {
    flex: 1 1 auto;
  }
}

.page-bg-half {
  position: fixed;
  top: 72px;
  bottom: 0;
  right: 0;
  left: 66.666666%;
  z-index: 99;
  display: flex;
  flex-flow: column;

  * {
    flex: 0 1 auto;
  }

  .page-preview {
    flex: 1 1 auto;
    overflow-y: scroll;
  }
}

.card {
  height: 200px;
  position: relative;
  .card-body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}

.accordion {
  overflow: hidden;
  .accordion-header {
    position: relative;
    i {
      position: absolute;
      top: 50%;
      left: -22px;
      transform: translateY(-50%) rotate(90deg);
    }
  }
}

.rst__placeholder {
  max-width: 350px;
  height: 88px;
}

.rst__placeholder::before {
  text-align: center;
  padding: 12px;
  content: "There's nothing here yet. Get started by adding an embodiment." !important;
}

.fig-display {
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.zero-state {
  border: 1px dashed #aaa;
  padding: 32px;
  text-align: center;
  border-radius: 4px;
  p {
    margin: 0;
    padding: 0;
    opacity: 0.9;
  }
}

.search {
  background: none;
  max-width: 400px;
  float: left;
  margin-right: auto;
  margin-left: 16px;
  .input-group-text {
    box-shadow: none;
    background: none;
    border: none;
    color: rgba(255,255,255,0.8);
  }
  input {
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
    padding-left: 0;
    border: none;
    color: #fff !important;
    &::placeholder {
      color: rgba(255,255,255,0.8);
    }
  }
}

.rst__rowSubtitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 700px;
  transform: translateY(2px);
}

.no-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
